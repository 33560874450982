export type LogLevelString = 'off'|'fatal'|'error'|'warning'|'information'|'debug'|'verbose'|string;

/**
 * Language definition
 */
export interface LanguageDef
{
    /**
     * Language shortcut used
     */
    lang: string;

    /**
     * Language display name
     */
    name: string;
}

/**
 * Configuration of keycloak
 */
export interface SettingsKeycloakConfiguration
{
    /**
     * Url address where is keycloak located
     */
    oauthServerUrl: string;

    /**
     * Name of keycloak realm to authenticate to
     */
    keycloakRealm: string;

    /**
     * Name of keycloak client to authenticate to
     */
    keycloakClient: string;

    /**
     * Name of keycloak mobile client name
     */
    keycloakMobileClient: string;

    /**
     * Default redirect URI
     */
    redirectUri: string;

    /**
     * Maximal inactivity time after which is user logged out
     */
    maxInactiveIntervalInSec: number;

    /**
     * Interval in which is "ping" service called
     */
    pingIntervalInSec: number;

    /**
     * Indication whether mock keycloak authentication
     */
    mock: boolean;
}

export interface SettingsConfiguration
{
    /**
     * Indication that application is running in debug mode
     */
    debug: boolean;

    /**
     * Indication that missing translation debugging should be enabled
     */
    debugTranslations: boolean;

    /**
     * Base url that is used for accessing REST api
     */
    apiBaseUrl: string;

    /**
     * Base url that is used for accessing keycloak REST api
     */
    keycloakApiBaseUrl: string;

    /**
     * Base url that is used for accessing keycloak REST api for mobile users
     */
    keycloakMobileApiBaseUrl: string;

    /**
     * Object hodling default headers that are send with rest requests
     */
    defaultApiHeaders: { [key: string]: string };

    /**
     * Array of available themes
     */
    themes: string[];

    /**
     * Available languages for application
     */
    languages: LanguageDef[];

    /**
     * Keycloak configuration
     */
    keycloak: SettingsKeycloakConfiguration;

    /**
     * Indication whether disable mock logger
     */
    disableMockLogger: boolean;
}

export interface SettingsGeneral
{
    /**
     * Default visual theme of application
     */
    theme: string;

    /**
     * Default selected language
     */
    language: string;
}

export interface SettingsDebug
{
    /**
     * Indication whether is console (logger sink) enabled
     */
    consoleEnabled: boolean;

    /**
     * Indication whether are debug data enabled
     */
    debugData: boolean;
}

export interface SettingsLogging
{
    /**
     * Minimal log level for console sink
     */
    consoleLogLevel: LogLevelString;
}

/**
 * Configuration object
 */
export interface Configuration
{
    /**
     * Static configuration for application
     */
    configuration?: SettingsConfiguration;

    /**
     * General settings
     */
    general?: SettingsGeneral;

    /**
     * Debug settings, used for debugging purposes
     */
    debug?: SettingsDebug;

    /**
     * Logging setting, allows to configure logger sinks
     */
    logging?: SettingsLogging;
}

export const config: Configuration = 
{
};